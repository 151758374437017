<template>
    <div>
        <v-row>
            <v-col cols="4">
                <v-select :items="['Phone']" label="Filters" outlined dense multiple required chips
                    v-model="filterItems" prepend-icon="" append-icon="mdi-filter"></v-select>
            </v-col>
            <v-col cols="4" v-show="filterItems.includes('Phone')">
                <v-text-field outlined dense v-model="filterByPhone" required label="Mobile Number"
                    append-icon="mdi-package"></v-text-field>
            </v-col>
        </v-row>
        <v-dialog v-model="imgdialog" max-width="450px">
            <v-card tile color="#f4f6f6">
                <v-card-text>
                    <v-container>
                        <v-img contain max-height="150" :src="selectedSrc"></v-img>
                    </v-container>
                </v-card-text>

                <v-divider class="primary" />
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="() => { imgdialog = false }">
                        Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="12">
                <v-checkbox @change="changeView()" label="Show Custom Protocol"></v-checkbox>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="450px" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                    Create
                </v-btn>
            </template>
            <v-card tile>
                <v-card-title class="primary white--text">
                    <span class="text-h5"><v-icon large color="white" class="mr-2">{{ formIcon }}</v-icon>{{ formTitle
                        }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row no-gutters>

                            <v-col cols="12">
                                <v-text-field outlined dense v-model="editedItem.name" :error-messages="nameErrors"
                                    required @input="editedIndex == -1 ? $v.editedItem.name.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedItem.name.$touch() : null" label="Protocol Name"
                                    append-icon="mdi-rename-box"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select dense outlined v-model="editedItem.protocol_type"
                                    :items="['Political', 'NGO']" label="Type" :error-messages="typeErrors" required
                                    @input="editedIndex == -1 ? $v.editedItem.protocol_type.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedItem.protocol_type.$touch() : null"
                                    append-icon="mdi-briefcase"></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select v-show="editedItem.protocol_type == 'Political'" outlined :multiple="true"
                                    dense :items="politicals" v-model="editedItem.political_party"
                                    :error-messages="political_partyErrors" required
                                    @input="editedIndex == -1 ? $v.editedItem.political_party.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedItem.political_party.$touch() : null"
                                    label="Political party" append-icon="mdi-gamepad-circle"></v-select>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <label>Is Premium</label>
                                <v-radio-group required v-model="editedItem.premium" row>
                                    <v-radio label="Yes" :value="true"></v-radio>
                                    <v-radio label="No" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>

                            <!-- Custom protocol fields -->
                            <v-col cols="12">
                                <label>Is Custom?</label>
                                <v-radio-group required v-model="editedItem.is_custom" row>
                                    <v-radio label="Yes" :value="true"></v-radio>
                                    <v-radio label="No" :value="false"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" v-if="editedItem.is_custom">
                                <v-autocomplete dense outlined v-model="editedItem.user_available_to" :items="customers"
                                    :multiple="true" label="User Available To" :error-messages="user_available_toErrors"
                                    required
                                    @input="editedIndex == -1 ? $v.editedItem.user_available_to.$touch() : null"
                                    @blur="editedIndex == -1 ? $v.editedItem.user_available_to.$touch() : null"
                                    append-icon="mdi-account"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedProtocolImage">
                                <v-img contain max-height="150" :src="selectedProtocolImage"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('custom_protocol')"
                                    v-model="editedItem.protocol_image" prepend-icon=""
                                    append-icon="mdi-image"></v-file-input>
                            </v-col>

                            <!-- Protocol Fields -->
                            <!-- show a textfield -->
                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl1 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl1"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl1')"
                                    v-model="editedItem.bgUrl1" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius1" label="Border Radius 1"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width1" label="Width 1"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height1" label="Height 1"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.margin1" label="Margin 1"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>

                            <!-- provide a apply all button -->
                            <!-- pad the bottom -->
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-btn color="primary" @click="applyAll()">Apply All</v-btn>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom" class="my-2">
                                <v-divider class="primary"></v-divider>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl2 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl2"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl2')"
                                    v-model="editedItem.bgUrl2" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius2" label="Border Radius 2"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width2" label="Width 2"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height2" label="Height 2"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.margin2" label="Margin 2"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl3 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl3"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl3')"
                                    v-model="editedItem.selectedBgUrl3" prepend-icon=""
                                    append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius3" label="Border Radius 3"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width3" label="Width 3"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height3" label="Height 3"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.margin3" label="Margin 3"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl4 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl4"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl4')"
                                    v-model="editedItem.bgUrl4" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius4" label="Border Radius 4"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width4" label="Width 4"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height4" label="Height 4"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.margin4" label="Margin 4"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl5 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl5"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl5')"
                                    v-model="editedItem.bgUrl5" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius5" label="Border Radius 5"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width5" label="Width 5"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height5" label="Height 5"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.margin5" label="Margin 5"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl6 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl6"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl6')"
                                    v-model="editedItem.bgUrl6" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius6" label="Border Radius 6"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width6" label="Width 6"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height6" label="Height 6"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.margin6" label="Margin 6"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl7 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl7"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl7')"
                                    v-model="editedItem.bgUrl7" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius7" label="Border Radius 7"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width7" label="Width 7"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height7" label="Height 7"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.margin7" label="Margin 7"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl8 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl8"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl8')"
                                    v-model="editedItem.bgUrl8" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius8" label="Border Radius 8"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width8" label="Width 8"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height8" label="Height 8"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.margin8" label="Margin 8"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>

                            <v-col cols="12" class="my-2 d-flex justify-center"
                                v-if="selectedBgUrl9 && !editedItem.is_custom">
                                <v-img contain max-height="150" :src="selectedBgUrl9"></v-img>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-file-input label="Cover" outlined dense @change="getImage('bgUrl9')"
                                    v-model="editedItem.bgUrl9" prepend-icon="" append-icon="mdi-image"></v-file-input>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.border_radius9" label="Border Radius 9"
                                    append-icon="mdi-image-filter-tilt-shift"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.width9" label="Width 9"
                                    append-icon="mdi-image-size-select-small"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom">
                                <v-text-field outlined dense v-model="editedItem.height9" label="Height 9"
                                    append-icon="mdi-image-size-select-large"></v-text-field>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.is_custom" class="mb-2">
                                <v-text-field outlined dense v-model="editedItem.margin9" label="Margin 9"
                                    append-icon="mdi-image-filter-none"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert v-if="error" dense type="error">
                                    {{ error }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider class="primary" />
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="success" @click="save">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text">
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDelete">Cancel
                    </v-btn>
                    <v-btn color="success" @click="deleteItemConfirm">OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table :headers="headers" :items="filterFinalData" sort-by="calories" class="elevation-3">
            <template v-slot:item.img="{ item }">
                <v-avatar class="profile my-2" color="grey" size="80" tile @click="openImage(item.protocol_image)">
                    <v-img contain v-if="item.is_custom" :src="item.protocol_image"></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.name="{ item }">
                <span class="text-h6">{{ item.name }}</span>
            </template>
            <template v-slot:item.protocol_type="{ item }">
                <span class="text-h6">{{ item.protocol_type }}</span>
            </template>
            <template v-slot:item.party_name="{ item }">
                <span class="text-h6">{{ item.party_name }}</span>
            </template>
            <template v-slot:item.premium="{ item }">
                <span class="text-h6">{{ item.premium }}</span>
            </template>
            <template v-slot:item.is_custom="{ item }">
                <span class="text-h6">{{ item.is_custom }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2 warning--text" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)" class="error--text">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
                <v-icon v-else x-large color="grey lighten-1">
                    mdi-tray-remove
                </v-icon>

            </template>
        </v-data-table>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'Protocol',
    mixins: [validationMixin],
    data: () => ({
        showCustomProtocol: false,
        imgdialog: false,
        selectedProtocolImage: null,
        selectedBgUrl1: null,
        selectedBgUrl2: null,
        selectedBgUrl3: null,
        selectedBgUrl4: null,
        selectedBgUrl5: null,
        customers: [],
        selectedBgUrl6: null,
        selectedBgUrl7: null,
        selectedBgUrl8: null,
        selectedBgUrl9: null,
        headers: [
            { text: 'Name', value: 'name' },
            { text: 'Protocol Type', value: 'protocol_type' },
            { text: 'Party Name', value: 'party_name' },
            { text: 'Premium', value: 'premium', sortable: false },
            { text: 'Custom', value: 'is_custom', sortable: false },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        data: [],
        allData: [],
        apiUrl: process.env.VUE_APP_API_URL,
        loading: true,
        dialog: false,
        dialogDelete: false,
        error: '',
        editedIndex: -1,
        politicals: [],
        editedItem: {
            name: '',
            protocol_type: '',
            political_party: [],
            premium: false,

            is_custom: false,
            user_available_to: [],
            protocol_image: '',

            // bgUrls: '',
            // border_radius: '',
            // widths: '',
            // heights: '',
            // margins: '',

            bgUrl1: '',
            border_radius1: '',
            width1: '',
            height1: '',
            margin1: '',

            bgUrl2: '',
            border_radius2: '',
            width2: '',
            height2: '',
            margin2: '',

            bgUrl3: '',
            border_radius3: '',
            width3: '',
            height3: '',
            margin3: '',

            bgUrl4: '',
            border_radius4: '',
            width4: '',
            height4: '',
            margin4: '',

            bgUrl5: '',
            border_radius5: '',
            width5: '',
            height5: '',
            margin5: '',

            bgUrl6: '',
            border_radius6: '',
            width6: '',
            height6: '',
            margin6: '',

            bgUrl7: '',
            border_radius7: '',
            width7: '',
            height7: '',
            margin7: '',

            bgUrl8: '',
            border_radius8: '',
            width8: '',
            height8: '',
            margin8: '',

            bgUrl9: '',
            border_radius9: '',
            width9: '',
            height9: '',
            margin9: '',
        },
        defaultItem: {
            name: '',
            protocol_type: '',
            political_party: [],
            premium: false,

            is_custom: false,
            user_available_to: [],
            protocol_image: '',

            bgUrl1: '',
            border_radius1: '',
            width1: '',
            height1: '',
            margin1: '',

            bgUrl2: '',
            border_radius2: '',
            width2: '',
            height2: '',
            margin2: '',

            bgUrl3: '',
            border_radius3: '',
            width3: '',
            height3: '',
            margin3: '',

            bgUrl4: '',
            border_radius4: '',
            width4: '',
            height4: '',
            margin4: '',

            bgUrl5: '',
            border_radius5: '',
            width5: '',
            height5: '',
            margin5: '',

            bgUrl6: '',
            border_radius6: '',
            width6: '',
            height6: '',
            margin6: '',

            bgUrl7: '',
            border_radius7: '',
            width7: '',
            height7: '',
            margin7: '',

            bgUrl8: '',
            border_radius8: '',
            width8: '',
            height8: '',
            margin8: '',

            bgUrl9: '',
            border_radius9: '',
            width9: '',
            height9: '',
            margin9: '',
        },
        filterItems: [],
        filterByName: '',
        filterByState: '',
        filterByPhone: ''
    }),
    created() {
        this.getProtocols();
        this.getAllPoliticals();
        this.getCustomers();
    },
    methods: {
        changeView() {
            this.showCustomProtocol = !this.showCustomProtocol

            // using all data to filter
            if (this.showCustomProtocol) {
                this.data = this.allData.filter((el) => {
                    return el.is_custom === true;
                });
            } else {
                // otherwise show only non-custom protocol
                this.data = this.allData.filter((el) => {
                    return el.is_custom === false;
                });
            }

            console.log("Show Custom Protocol: ", this.showCustomProtocol)
            if (this.showCustomProtocol === true) {
                console.log("Showing Custom Protocol")
            } else {
                console.log("Showing Non-Custom Protocol")
            }
        },
        openImage(src) {
            this.selectedSrc = src
            this.imgdialog = true
        },
        editItem(item) {
            console.log("Item: ", item);
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);

            // unpack the json list of bgUrls, assign them to the selectedBgUrl1, selectedBgUrl2, etc
            if (item.is_custom == false) {
                this.selectedBgUrl1 = item.bgUrls[0]
                this.selectedBgUrl2 = item.bgUrls[1]
                this.selectedBgUrl3 = item.bgUrls[2]
                this.selectedBgUrl4 = item.bgUrls[3]
                this.selectedBgUrl5 = item.bgUrls[4]
                this.selectedBgUrl6 = item.bgUrls[5]
                this.selectedBgUrl7 = item.bgUrls[6]
                this.selectedBgUrl8 = item.bgUrls[7]
                this.selectedBgUrl9 = item.bgUrls[8]

                // border_radius
                this.editedItem.border_radius1 = item.border_radius[0]
                this.editedItem.border_radius2 = item.border_radius[1]
                this.editedItem.border_radius3 = item.border_radius[2]
                this.editedItem.border_radius4 = item.border_radius[3]
                this.editedItem.border_radius5 = item.border_radius[4]
                this.editedItem.border_radius6 = item.border_radius[5]
                this.editedItem.border_radius7 = item.border_radius[6]
                this.editedItem.border_radius8 = item.border_radius[7]
                this.editedItem.border_radius9 = item.border_radius[8]

                // width
                this.editedItem.width1 = item.widths[0]
                this.editedItem.width2 = item.widths[1]
                this.editedItem.width3 = item.widths[2]
                this.editedItem.width4 = item.widths[3]
                this.editedItem.width5 = item.widths[4]
                this.editedItem.width6 = item.widths[5]
                this.editedItem.width7 = item.widths[6]
                this.editedItem.width8 = item.widths[7]
                this.editedItem.width9 = item.widths[8]

                // height
                this.editedItem.height1 = item.heights[0]
                this.editedItem.height2 = item.heights[1]
                this.editedItem.height3 = item.heights[2]
                this.editedItem.height4 = item.heights[3]
                this.editedItem.height5 = item.heights[4]
                this.editedItem.height6 = item.heights[5]
                this.editedItem.height7 = item.heights[6]
                this.editedItem.height8 = item.heights[7]
                this.editedItem.height9 = item.heights[8]

                // margin
                this.editedItem.margin1 = item.margins[0]
                this.editedItem.margin2 = item.margins[1]
                this.editedItem.margin3 = item.margins[2]
                this.editedItem.margin4 = item.margins[3]
                this.editedItem.margin5 = item.margins[4]
                this.editedItem.margin6 = item.margins[5]
                this.editedItem.margin7 = item.margins[6]
                this.editedItem.margin8 = item.margins[7]
                this.editedItem.margin9 = item.margins[8]
            } else {
                this.selectedProtocolImage = item.protocol_image
            }





            this.dialog = true;
            console.log(this.editedItem);
        },
        deleteItem(item) {
            this.editedIndex = this.data.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editedItem.premium = item.premium;
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.deleteProtocol(this.editedItem.id)
            this.closeDelete();
        },
        close() {
            this.selectedImage = null
            this.selectedSocialImage = null
            this.dialog = false;
            this.$v.$reset()
            this.error = ''
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        save() {
            this.editedIndex == -1 ? this.addProtocol() : this.updateProtocol()
        },
        //Frames API Calls
        getProtocols() {
            this.loading = true
            this.$Axios
                // original - get-frames
                // new added - get-frames-demo
                .get('/api/v1/protocol?admin=true')
                .then((res) => {
                    console.log(res.data);
                    this.allData = res.data;

                   

                    if (this.showCustomProtocol) {
                        this.data = this.allData.filter((item) => item.is_custom == true);
                    } else {
                        this.data = this.allData.filter((item) => item.is_custom == false);
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false
                })
        },
        applyAll() {
            // ensure all fields are filled for the 1 field
            if (this.selectedBgUrl1 && this.editedItem.border_radius1 && this.editedItem.width1 && this.editedItem.height1 && this.editedItem.margin1) {
                this.selectedBgUrl2 = this.selectedBgUrl1
                this.editedItem.bgUrl2 = this.editedItem.bgUrl1
                this.editedItem.border_radius2 = this.editedItem.border_radius1
                this.editedItem.width2 = this.editedItem.width1
                this.editedItem.height2 = this.editedItem.height1
                this.editedItem.margin2 = this.editedItem.margin1

                this.selectedBgUrl3 = this.selectedBgUrl1
                this.editedItem.bgUrl3 = this.editedItem.bgUrl1
                this.editedItem.border_radius3 = this.editedItem.border_radius1
                this.editedItem.width3 = this.editedItem.width1
                this.editedItem.height3 = this.editedItem.height1
                this.editedItem.margin3 = this.editedItem.margin1

                this.selectedBgUrl4 = this.selectedBgUrl1
                this.editedItem.bgUrl4 = this.editedItem.bgUrl1
                this.editedItem.border_radius4 = this.editedItem.border_radius1
                this.editedItem.width4 = this.editedItem.width1
                this.editedItem.height4 = this.editedItem.height1
                this.editedItem.margin4 = this.editedItem.margin1

                this.selectedBgUrl5 = this.selectedBgUrl1
                this.editedItem.bgUrl5 = this.editedItem.bgUrl1
                this.editedItem.border_radius5 = this.editedItem.border_radius1
                this.editedItem.width5 = this.editedItem.width1
                this.editedItem.height5 = this.editedItem.height1
                this.editedItem.margin5 = this.editedItem.margin1

                this.selectedBgUrl6 = this.selectedBgUrl1
                this.editedItem.bgUrl6 = this.editedItem.bgUrl1
                this.editedItem.border_radius6 = this.editedItem.border_radius1
                this.editedItem.width6 = this.editedItem.width1
                this.editedItem.height6 = this.editedItem.height1
                this.editedItem.margin6 = this.editedItem.margin1

                this.selectedBgUrl7 = this.selectedBgUrl1
                this.editedItem.bgUrl7 = this.editedItem.bgUrl1
                this.editedItem.border_radius7 = this.editedItem.border_radius1
                this.editedItem.width7 = this.editedItem.width1
                this.editedItem.height7 = this.editedItem.height1
                this.editedItem.margin7 = this.editedItem.margin1

                this.selectedBgUrl8 = this.selectedBgUrl1
                this.editedItem.bgUrl8 = this.editedItem.bgUrl1
                this.editedItem.border_radius8 = this.editedItem.border_radius1
                this.editedItem.width8 = this.editedItem.width1
                this.editedItem.height8 = this.editedItem.height1
                this.editedItem.margin8 = this.editedItem.margin1


                this.selectedBgUrl9 = this.selectedBgUrl1
                this.editedItem.bgUrl9 = this.editedItem.bgUrl1
                this.editedItem.border_radius9 = this.editedItem.border_radius1
                this.editedItem.width9 = this.editedItem.width1
                this.editedItem.height9 = this.editedItem.height1
                this.editedItem.margin9 = this.editedItem.margin1

                alert("All fields have been filled")
            } else {
                alert("Please fill all fields for the first field")
            }
        },
        getImage(source) {
            let formData = new FormData();
            if (source == 'custom_protocol') formData.append('img', this.editedItem.protocol_image);
            else if (source == 'bgUrl1') formData.append('img', this.editedItem.bgUrl1);
            else if (source == 'bgUrl2') formData.append('img', this.editedItem.bgUrl2);
            else if (source == 'bgUrl3') formData.append('img', this.editedItem.bgUrl3);
            else if (source == 'bgUrl4') formData.append('img', this.editedItem.bgUrl4);
            else if (source == 'bgUrl5') formData.append('img', this.editedItem.bgUrl5);
            else if (source == 'bgUrl6') formData.append('img', this.editedItem.bgUrl6);
            else if (source == 'bgUrl7') formData.append('img', this.editedItem.bgUrl7);
            else if (source == 'bgUrl8') formData.append('img', this.editedItem.bgUrl8);
            else if (source == 'bgUrl9') formData.append('img', this.editedItem.bgUrl9);
            formData.append('source', 'protocol');
            this.$Axios({
                method: 'post',
                url: '/api/v1/util/upload-image',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
            })
                .then(({ data }) => {

                    // data.img should be a url, else add a alert and return
                    if (!data.img) {
                        alert("Invalid image")
                        return
                    } 
                    //  check for url 
                    else if (data.img.includes("http") == false) {
                        alert("Invalid image")
                        return
                    }

                    if (source == 'custom_protocol') {
                        this.selectedProtocolImage = data.img;
                    }
                    else if (source == 'bgUrl1') {
                        this.selectedBgUrl1 = data.img;
                    }
                    else if (source == 'bgUrl2') {
                        this.selectedBgUrl2 = data.img;
                    }
                    else if (source == 'bgUrl3') {
                        this.selectedBgUrl3 = data.img;
                    }
                    else if (source == 'bgUrl4') {
                        this.selectedBgUrl4 = data.img;
                    }
                    else if (source == 'bgUrl5') {
                        this.selectedBgUrl5 = data.img;
                    }
                    else if (source == 'bgUrl6') {
                        this.selectedBgUrl6 = data.img;
                    }
                    else if (source == 'bgUrl7') {
                        this.selectedBgUrl7 = data.img;
                    }
                    else if (source == 'bgUrl8') {
                        this.selectedBgUrl8 = data.img;
                    }
                    else if (source == 'bgUrl9') {
                        this.selectedBgUrl9 = data.img;
                    }
                })
                .catch((e) => {
                    console.log(e)
                });
        },
        addProtocol() {

             // detect select all in political_party
            if (this.editedItem.political_party.includes("Select All")) {
                this.editedItem.political_party = this.politicals.map((el) => {
                    return el.value
                })
                this.editedItem.political_party.shift();
            }

            let data = {}

             // detect select all in user_available_to
            if (this.editedItem.user_available_to == "Select All") {
                this.editedItem.user_available_to = this.customers.map((el) => {
                    return el.value
                })
            }
            // if custom, ensure user_available_to is filled and protocol_image is filled
            if (this.editedItem.is_custom) {
                if (this.editedItem.user_available_to && this.editedItem.protocol_image) {
                    data = {
                        name: this.editedItem.name,
                        protocol_type: this.editedItem.protocol_type,
                        political_party: this.editedItem.political_party,
                        premium: this.editedItem.premium.toString(),
                        is_custom: this.editedItem.is_custom.toString(),
                        user_available_to: this.editedItem.user_available_to,
                        protocol_image: this.selectedProtocolImage
                    }
                } else {
                    alert("Please fill all fields")
                    return
                }
            } else {
                // if not custom, ensure all fields are filled
                if (this.editedItem.name && this.editedItem.protocol_type && this.editedItem.premium !== undefined && this.editedItem.premium !== null) {
                    data = {
                        name: this.editedItem.name,
                        protocol_type: this.editedItem.protocol_type,
                        political_party: this.editedItem.political_party,
                        premium: this.editedItem.premium.toString(),
                        is_custom: this.editedItem.is_custom.toString(),
                        user_available_to: this.editedItem.user_available_to,
                        protocol_image: this.editedItem.protocol_image,
                        widths: [this.editedItem.width1, this.editedItem.width2, this.editedItem.width3, this.editedItem.width4, this.editedItem.width5, this.editedItem.width6, this.editedItem.width7, this.editedItem.width8, this.editedItem.width9],
                        heights: [this.editedItem.height1, this.editedItem.height2, this.editedItem.height3, this.editedItem.height4, this.editedItem.height5, this.editedItem.height6, this.editedItem.height7, this.editedItem.height8, this.editedItem.height9],
                        margins: [this.editedItem.margin1, this.editedItem.margin2, this.editedItem.margin3, this.editedItem.margin4, this.editedItem.margin5, this.editedItem.margin6, this.editedItem.margin7, this.editedItem.margin8, this.editedItem.margin9],
                        border_radius: [this.editedItem.border_radius1, this.editedItem.border_radius2, this.editedItem.border_radius3, this.editedItem.border_radius4, this.editedItem.border_radius5, this.editedItem.border_radius6, this.editedItem.border_radius7, this.editedItem.border_radius8, this.editedItem.border_radius9],
                        bgUrls: [this.selectedBgUrl1, this.selectedBgUrl2, this.selectedBgUrl3, this.selectedBgUrl4, this.selectedBgUrl5, this.selectedBgUrl6, this.selectedBgUrl7, this.selectedBgUrl8, this.selectedBgUrl9],
                    }
                } else {
                    alert("Please fill all fields")
                    return
                }
            }

            this.$Axios
                .post('/api/v1/protocol/', data)
                .then(() => {
                    this.getProtocols();
                    this.close();
                })
                .catch((e) => {
                    console.log(e);
                    if (e.response.status === 422) {
                        alert('Missing required fields!');
                    } else {
                        console.log(e?.response);
                    }
                });
        },
        updateProtocol() {
            this.error = ''
            this.$v.$touch()
            let data = {}

            // detect select all in political_party
            if (this.editedItem.political_party.includes("Select All")) {
                this.editedItem.political_party = this.politicals.map((el) => {
                    return el.value
                })
                this.editedItem.political_party.shift();
            }

            console.log("Edited Item: ", this.editedItem)

            // if custom, ensure user_available_to is filled and protocol_image is filled
            if (this.editedItem.is_custom) {
                if (this.editedItem.user_available_to && this.editedItem.protocol_image) {
                    data = {
                        name: this.editedItem.name,
                        protocol_type: this.editedItem.protocol_type,
                        political_party: this.editedItem.political_party,
                        premium: this.editedItem.premium.toString(),
                        is_custom: this.editedItem.is_custom.toString(),
                        user_available_to: this.editedItem.user_available_to,
                        protocol_image: this.selectedProtocolImage,
                    }
                } else {
                    alert("Please fill all fields")
                    return
                }
            } else {
                // if not custom, ensure all fields are filled
                if (this.editedItem.name && this.editedItem.protocol_type && this.editedItem.political_party && this.editedItem.premium !== undefined && this.editedItem.premium !== null) {
                    data = {
                        name: this.editedItem.name,
                        protocol_type: this.editedItem.protocol_type,
                        political_party: this.editedItem.political_party,
                        premium: this.editedItem.premium.toString(),
                        is_custom: this.editedItem.is_custom.toString(),
                        user_available_to: this.editedItem.user_available_to,
                        protocol_image: this.editedItem.protocol_image,
                        widths: [this.editedItem.width1, this.editedItem.width2, this.editedItem.width3, this.editedItem.width4, this.editedItem.width5, this.editedItem.width6, this.editedItem.width7, this.editedItem.width8, this.editedItem.width9],
                        heights: [this.editedItem.height1, this.editedItem.height2, this.editedItem.height3, this.editedItem.height4, this.editedItem.height5, this.editedItem.height6, this.editedItem.height7, this.editedItem.height8, this.editedItem.height9],
                        margins: [this.editedItem.margin1, this.editedItem.margin2, this.editedItem.margin3, this.editedItem.margin4, this.editedItem.margin5, this.editedItem.margin6, this.editedItem.margin7, this.editedItem.margin8, this.editedItem.margin9],
                        border_radius: [this.editedItem.border_radius1, this.editedItem.border_radius2, this.editedItem.border_radius3, this.editedItem.border_radius4, this.editedItem.border_radius5, this.editedItem.border_radius6, this.editedItem.border_radius7, this.editedItem.border_radius8, this.editedItem.border_radius9],
                        bgUrls: [this.selectedBgUrl1, this.selectedBgUrl2, this.selectedBgUrl3, this.selectedBgUrl4, this.selectedBgUrl5, this.selectedBgUrl6, this.selectedBgUrl7, this.selectedBgUrl8, this.selectedBgUrl9],
                    }
                } else {
                    alert("Please fill all fields")
                    return
                }
            }

            console.log("Edited Item: ", this.editedItem)
            console.log("Sending data: ", data);

            this.$Axios.put('/api/v1/protocol/' + this.editedItem.id, data)
                .then((res) => {
                    console.log("Data: ", res.data);
                    this.getProtocols();
                    this.close();
                })
                .catch((e) => {
                    console.log(e)
                    alert(e)
                });
        },
        getCustomers() {
            this.loading = true
            this.$Axios
                .get('/api/v1/customer')
                .then((res) => {
                    console.log(res.data);
                    // this.customers = res.data;

                    this.customers = res.data.map((el) => {
                        return { value: el.user_id, text: el.phone }
                    })
                    // add select all option
                    // this.customers.unshift({ value: "Select All", text: "Select All" });

                    // this.politicals = res.data.map((el) => {
                    //     return { value: el.id, text: el.name }
                    // })
                    // // add select all option
                    // this.politicals.unshift({ value: "Select All", text: "Select All" });
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteProtocol(id) {
            this.$Axios
                .delete('/api/v1/protocol/' + id)
                .then(() => {
                    this.getProtocols();
                })
                .catch((e) => {
                    console.log(e);
                })
        },
        getAllPoliticals() {
            this.$Axios
                .get('/api/v1/political/')
                .then((res) => {
                    console.log("All politicals, ", res.data);
                    this.politicals = res.data.map((el) => {
                        return { value: el.id, text: el.name }
                    })

                    // add select all option
                    this.politicals.unshift({ value: "Select All", text: "Select All" });
                })
                .catch((e) => {
                    console.log(e);
                })
        },
       
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Frame' : 'Edit Frame';
        },
        formIcon() {
            return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
        },
        filtredItems() {
            if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter)
                || item.license.includes(this.filter)
                || item.email.includes(this.filter)
                || item.db.includes(this.filter))
            else return this.desserts
        },
        //// Vuelidate Errors
        nameErrors() {
            const errors = []
            if (!this.$v.editedItem.name.$dirty) return errors
            !this.$v.editedItem.name.required && errors.push('Name is required !')
            return errors
        },
        imgErrors() {
            const errors = []
            if (!this.$v.editedItem.img.$dirty) return errors
            !this.$v.editedItem.img.required && errors.push('Cover is required !')
            return errors
        },
        social_imgErrors() {
            const errors = []
            if (!this.$v.editedItem.social_img.$dirty) return errors
            !this.$v.editedItem.social_img.required && errors.push('Social Frame is required !')
            return errors
        },
        font_colourErrors() {
            const errors = []
            if (!this.$v.editedItem.font_colour.$dirty) return errors
            !this.$v.editedItem.font_colour.required && errors.push('Font color is required')
            return errors
        },
        social_font_colourErrors() {
            const errors = []
            if (!this.$v.editedItem.social_font_colour.$dirty) return errors
            !this.$v.editedItem.social_font_colour.required && errors.push('Social Font color is required')
            return errors
        },
        political_partyErrors() {
            const errors = []
            if (!this.$v.editedItem.political_party.$dirty) return errors
            !this.$v.editedItem.political_party.required && errors.push('Political party is required')
            return errors
        },
        typeErrors() {
            const errors = []
            if (!this.$v.editedItem.frame_type.$dirty) return errors
            !this.$v.editedItem.frame_type.required && errors.push('Type is required')
            return errors
        },
         filterFinalData() {
            if (this.filterItems.length === 0) {
                return this.data
            } else {
                //   //moment(this.editedItem.date).format('YYYY-MM_DD')
                let data = this.data
                if (this.filterItems.includes('Phone') && this.filterByPhone) {
                    data = data.filter((el) => {
                        // convert the user_available_to from the user id to the phone number and check if it includes the filterByPhone
                        return el.user_available_to.map((el) => {
                            return this.customers.find((customer) => {
                                return customer.value === el
                            }).text
                        }).includes(this.filterByPhone)
                    });
                }
                return data
            }
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    validations: {
        editedItem: {
            name: {
                required
            },
            img: {
                required,
            },
            social_img: {
                required,
            },
            font_colour: {
                required
            },
            social_font_colour: {
                required
            },
            political_party: {
                required
            },
            premium: {
                required
            },
            frame_type: {
                required
            }
        },
    },
};
</script>